import { Container, Row, Col } from "react-bootstrap";
import { StarFill } from "react-bootstrap-icons";
import "./offer.css";

function Offer(props) {
  return (
    <Container className="offer">
      <Row className="offers offerOne">
        <Col>
          <div className="offerOneText">
            <h4>RESULT DRIVEN ETHOS</h4>
            <h2>STATE OF THE ART WEB DESIGN</h2>
            <p>
              We are incredibly passionate about our craft and the results our
              work drives for our clients. We understand the importance of
              speed, security and great UI design for both your website visitors
              and your business{" "}
            </p>

            <div className="offerButtons">
              <a className="offerButtonOne" onClick={props.handleOpen}>
                GET STARTED
              </a>
              <a className="offerButtonTwo" href="#portfolio">
                OUR WORKS
              </a>
            </div>
          </div>
        </Col>
        <Col>
          <div className="offerImg">
            <img src="soloTest.png" alt="state_of_the_art_web_design" />
          </div>
        </Col>
      </Row>

      <Row className="offerTwo">
        <Col>
          <div className="offerImg">
            <img
              src="aboutusnobg.png"
              alt="All_our_sites_are_mobile_optimized"
            />
          </div>
        </Col>

        <Col>
          <div className="offerTwoText">
            <h4>ALL OUR SITES ARE</h4>
            <h2>MOBILE DEVICE OPTIMISED</h2>
            <p>
              Along with our management and maintenance plans all our websites
              come optimised for mobile, tablet and laptop screens. Our builds
              are developed in 3 stages
            </p>
            <ol>
              <li>Laptop Experience</li>
              <li>Tablet Experience</li>
              <li>Mobile Experience</li>
            </ol>
            <p>
              We understand the experience must be cohesive on all devices. Be
              rest assured that your potential clients will be pleased with
              their visit.
            </p>
            <div className="offerButtons">
              <a className="offerButtonOne" onClick={props.handleOpen}>
                Contact Us
              </a>
            </div>
            <div className="offerTwoReview">
              <div className="offerTwoReviewText">
                <p>Reviews 5/5</p>
              </div>
              <span>
                <StarFill style={{ color: "#e60023" }} />
                <StarFill style={{ color: "#e60023" }} />
                <StarFill style={{ color: "#e60023" }} />
                <StarFill style={{ color: "#e60023" }} />
                <StarFill style={{ color: "#e60023" }} />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Offer;
