import "bootstrap/dist/css/bootstrap.css";

import { Container, Navbar, Nav, Modal, Button, Form } from "react-bootstrap";

import { Facebook, Twitter, Whatsapp, Instagram } from "react-bootstrap-icons";

import "./App.css";
import { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

import Header from "./layout/Header/Header";
import WhoWeAre from "./layout/WhoWeAre/WhoWeAre";
import Offer from "./layout/Offer/Offer";
import Portfolio from "./layout/Portfolio/Portfolio";
import Testimonial from "./layout/Testimonials/Testimonial";
import CTA from "./layout/CTA/CTA";
import Footer from "./layout/Footer/Footer";

function App() {
  const [show, setShow] = useState(false);
  const [state, handleSubmit] = useForm("xkndbznr");

  const handleClose = () => setShow(false);

  const handleOpen = () => setShow(true);

  return (
    <div className="App">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand
            href="#home"
            style={{ width: "150px", marginRight: "auto" }}
          >
            <img
              alt="Francis_Innovates Logo"
              style={{ maxWidth: "100%" }}
              src="mainLogo.png"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navigation">
              <Nav.Link href="#header">Home</Nav.Link>
              <Nav.Link href="#aboutUs">About Us</Nav.Link>
              <Nav.Link href="#testimonials">Testimonials</Nav.Link>
              <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            </Nav>

            <Nav className="ms-auto">
              <Nav.Link
                href="https://web.facebook.com/profile.php?id=61557033651113"
                className="navLink"
              >
                <Facebook />
              </Nav.Link>
              <Nav.Link
                href="https://twitter.com/FrancisInovates"
                className="navLink"
              >
                <Twitter />
              </Nav.Link>
              <Nav.Link
                href="https://wa.me/message/J62F7FYD7D2XL1"
                className="navLink"
              >
                <Whatsapp />
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/francisinnovates/"
                className="navLink"
              >
                <Instagram />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <main>
        <Header handleOpen={handleOpen} />
        <WhoWeAre />
        <Offer handleOpen={handleOpen} />
        <Portfolio />
        <Testimonial />
        <CTA handleOpen={handleOpen} />
        <Footer />
      </main>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {state.succeeded ? "Received" : "Get In Touch With Us"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.succeeded ? (
            <h2>Thanks for reaching out to us, we'll get back to you soon!</h2>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label htmlFor="Name">Name</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter your name"
                  required
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="name@example.com"
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  id="number"
                  name="number"
                  type="text"
                  placeholder="Enter phone number"
                  required
                />
                <ValidationError
                  prefix="Number"
                  field="number"
                  errors={state.errors}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.controlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  id="description"
                  name="description"
                  as="textarea"
                  rows={3}
                  placeholder="How can we be of service to you ?"
                  required
                />
                <ValidationError
                  prefix="Description"
                  field="description"
                  errors={state.errors}
                />
              </Form.Group>

              {state.succeeded ? (
                ""
              ) : (
                <Button
                  className="modalBtn"
                  type="submit"
                  disabled={state.submitting}
                >
                  Submit
                </Button>
              )}

              <Button className="modalBtn" onClick={handleClose}>
                Close
              </Button>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          OR CALL US &rarr; <span> +234-815-387-1991</span>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
