import { Container, Row, Col } from "react-bootstrap";
import "./portfolio.css";

function Portfolio() {
  return (
    <Container className="portfolio" id="portfolio">
      <h4>VIEW OUR</h4>
      <h2>Portfolio</h2>
      <p>Here are some of our latest projects:</p>

      <Row className="portfolioDetails">
        <Col className="portfolioCol">
          <figure className="portfolioBox portfolioOne">
            <figcaption className="portfolioInfo">
              <h2>PULCHHAIRCARE</h2>
              <a href="https://www.pulchhaircare.com.ng">Visit &rarr; </a>
            </figcaption>
          </figure>
        </Col>
        <Col className="portfolioCol">
          <figure className="portfolioBox portfolioTwo">
            <figcaption className="portfolioInfo">
              <h2>SOLOCHAT</h2>
              <a href="https://www.solochat.me">Visit &rarr; </a>
            </figcaption>
          </figure>
        </Col>
      </Row>
    </Container>
  );
}

export default Portfolio;
