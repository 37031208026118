import { Facebook, Twitter, Whatsapp, Instagram } from "react-bootstrap-icons";

import "./footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div
        className="footerLogo"
        style={{ width: "200px", marginRight: "auto" }}
      >
        <img
          alt="Francis_Innovates Logo"
          style={{ maxWidth: "100%" }}
          src="mainLogo.png"
          className="d-inline-block align-top"
        />
      </div>
      <ul>
        <li>
          <a href="#header">Home</a>
        </li>
        <li>
          <a href="#aboutUs">About</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
      </ul>
      <div className="footerSocial">
        <a href="https://web.facebook.com/profile.php?id=61557033651113">
          <Facebook className="footerSocialIcon" size={25} />
        </a>
        <a href="https://twitter.com/FrancisInovates">
          <Twitter className="footerSocialIcon" size={25} />
        </a>
        <a href="https://wa.me/message/J62F7FYD7D2XL1">
          <Whatsapp className="footerSocialIcon" size={25} />
        </a>
        <a href="https://www.instagram.com/francisinnovates/">
          <Instagram className="footerSocialIcon" size={25} />
        </a>
      </div>
      <div className="footerCopyright">
        <small>
          {" "}
          &copy; COPYRIGHT FRANCIS INNOVATES {new Date().getFullYear()}. All
          Rights Reserved
        </small>
      </div>
    </footer>
  );
}

export default Footer;
