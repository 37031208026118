import { Container, Row, Col } from "react-bootstrap";
import { Quote } from "react-bootstrap-icons";
import "./testimonial.css";

export default function Testimonial() {
  return (
    <Container className="testimonials" id="testimonials">
      <div className="testimonialsHeader">
        <h4>CLIENTS</h4>
        <h2>TESTIMONIALS</h2>
      </div>

      <Row className="testimonialsCon">
        <Col className="testimonialBox">
          <p>
            <Quote /> Francis redesigned and built the UI of our Company's
            website. His work was impressive, he gave a new look to the feel and
            design of our website which resulted in an increased traffic and
            impressions gained by our customers by 15%. I recommend Francis
            Innovates, they are creative and an out-of-the-box thinkers. You can
            always rely on them, with little or no supervision.
          </p>

          <div className="testimonialAuthor">
            <div className="testimonialAuthorImg">
              <img src="Favour.jpeg" alt="favour_founder_soloChat" />
            </div>
            <div className="testimonialAuthorText">
              <small className="authorName">Favour Gabriel</small>
              <small>
                Founder and CEO -{" "}
                <a href="https://www.solochat.me"> SoloChat</a>
              </small>
            </div>
          </div>
        </Col>

        <Col className="testimonialBox">
          <p>
            <Quote /> I enlisted the services of Francis Innovates to build our
            website. I must say the outcome is truly amazing. I'm thoroughly
            satisfied with the services provided. Overall, my experience working
            with Francis Innovates was excellent. I highly recommend them for
            their website development services{" "}
          </p>

          <div className="testimonialAuthor">
            <div className="testimonialAuthorImg">
              <img src="constance.png" alt="constance_founder_pulch_haircare" />
            </div>
            <div className="testimonialAuthorText">
              <small className="authorName">Constance</small>
              <small>
                Founder -{" "}
                <a href="https://www.pulchhaircare.com.ng"> Pulch HairCare</a>
              </small>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
