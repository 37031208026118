import "./header.css";

function Header(props) {
  return (
    <section className="main" id="header">
      <div className="mainBgVideo">
        <video className="mainBgVideoContent" autoPlay muted loop>
          <source src="herovideo.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="mainHeader">
        <h2 className="heroMain">
          <span>Web Design.</span> <span>Marketing.</span>
        </h2>
        <h2 className="heroSub">
          We design and develop exceptional digital products and services to
          grow your business in the digital space{" "}
        </h2>
        <button className="heroBtn" onClick={props.handleOpen}>
          Contact Us Now
        </button>
      </div>
    </section>
  );
}

export default Header;
