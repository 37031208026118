import { Container } from "react-bootstrap";
import "./cta.css";

function CTA(props) {
  return (
    <Container className="callToAction">
      <div className="callToActionText">
        <h2>Let's discuss about how we can help make your business better</h2>
      </div>
      <button className="cta" onClick={props.handleOpen}>
        Contact Us
      </button>
    </Container>
  );
}

export default CTA;
