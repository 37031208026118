import { Container } from "react-bootstrap";
import "./whoweare.css";

function WhoWeAre() {
  return (
    <Container className="whoWeAre" id="aboutUs">
      <h4>Who We Are</h4>
      <h2>How Our Agency Can Help Your Business</h2>
      <p>
        Whether your company is rebranding an existing site or building its
        first site. Francis Innovates line of products is sure to resolve your
        digital needs. Our services are tailored to drive the much needed
        results needed to grow your brand and business.
      </p>
      <ul>
        <li>Branding</li>
        <li>Graphics Design</li>
        <li>Web Design & Development</li>
        <li>Industry Leading CMS</li>
        <li>Digital Marketing</li>
      </ul>
    </Container>
  );
}

export default WhoWeAre;
